import React from 'react';
import styled from 'styled-components';
import { AlertProps as AlertStrapProps, Alert as AlertStrap } from 'reactstrap';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const StyledAlert = styled(AlertStrap)`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 10px;
  border: none;
  border-radius: 22px;
  color: #1d1d1d !important;
`;

export interface AlertProps extends AlertStrapProps {
  children: any;
}
export const Alert = (props: AlertProps) => (
  <StyledAlert {...props}>
    <IoIosInformationCircleOutline size={23} className="mr-2" />
    {props.children}
  </StyledAlert>
);
