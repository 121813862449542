import React from 'react';
import { StyledTr, StyledTd } from '../../../index';
import { getHTMLFromText } from '../../../../../../helpers/html';
import { ISubscriptionItem } from '../../../../../../modules/types/subscriptions';

interface ISubtitle {
  subtitle: string;
  extraInfo?: string;
  subscriptions: ISubscriptionItem[];
}

const Subtitle = ({ subtitle, extraInfo, subscriptions }: ISubtitle) => {
  const isSingleSubscription = subscriptions.length === 1;

  return (
    <StyledTr disableLine>
      <StyledTd>{getHTMLFromText(subtitle)}</StyledTd>
      <StyledTd>{isSingleSubscription && <b>{extraInfo}</b>}</StyledTd>
    </StyledTr>
  );
};

export default Subtitle;
