import React, { useEffect } from 'react';
import { getTranslationKey } from '../../../modules/utils/helpers';
import styled from 'styled-components';
import useModalHelpers from '../useModalHelpers';
import { Button } from 'reactstrap';
import { getEuros } from '../../../pages/OverviewTab/Overview/components/subscriptions';
import { useSelector } from 'react-redux';
import { getUser } from '../../../modules/selectors/user';
import { getPaymentInfo } from '../../../modules/selectors/paymentInfo';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../../../modules/types';
import TotalItem from '../../../pages/OverviewTab/Overview/components/subscriptions/totalItem';
import { Alert } from '@material-ui/core';
import useTracking from '../../../hooks/tracking/use-tracking';

const StyledTable = styled.table`
  && {
    width: 100%;
    th {
      padding-top: 15px;
      font-weight: initial;
      &:last-child {
        text-align: end;
      }
    }
    td:last-child {
      text-align: end;
    }
  }
`;

const TableTitle = styled.span`
  padding-right: 20px;
`;

const PlanTitle = styled.span`
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
`;

const CoreItem = ({ plan }: { plan: string }) => (
  <TableTitle>
    scrappbook: <PlanTitle>{plan}</PlanTitle>
  </TableTitle>
);

const AddonItem = ({ type }: { type: string }) => (
  <TableTitle>
    Addon: <PlanTitle>{type}</PlanTitle>
  </TableTitle>
);

export const StyledTr = styled.tr<{ disableLine?: boolean }>`
  border-bottom: ${({ disableLine }) => (!disableLine ? '1px solid lightgray' : '')};
`;

export const StyledTd = styled.td<{ paddingTop?: number; align?: string }>`
  padding-top: ${({ paddingTop }) => (paddingTop !== undefined ? paddingTop + 'px' : '15px')};
  text-align: ${({ align }) => (align ? `${align} !important` : 'initial')};
`;

const FourthStep = () => {
  const { claimOffer, isApplyingOffer, isOpen } = useModalHelpers();
  const trackAction = useTracking();

  useEffect(() => {
    if (isOpen) {
      trackAction('modal-window-opened', {
        modal_id: 'before-churn-offer',
        url: window.location.href
      });
    }
  }, [isOpen]); // eslint-disable-line

  const { subscription = {} } = useSelector(getUser);
  const { subscriptions } = useSelector(getPaymentInfo);

  const coreAndBelongingAddonSubscriptions = subscriptions.filter(
    (s) => s.subscriptionType === 'core' || !s.stripeSubscriptionId
  );

  const currency = subscriptions[0]?.currency || SHOP_CURRENCIES.EUR;

  const coreSubscriptionIntervall = (subscription?.intervall || '') as string;

  const defaultAmount = coreAndBelongingAddonSubscriptions.reduce(
    (acc, item) => (acc += item?.amountCentsGross || 0),
    0
  );
  const discountedAmount = defaultAmount * 0.7;
  const savingsAmount = defaultAmount - discountedAmount;

  return (
    <div>
      <h2 className="text-center">{getTranslationKey('cancelSubscriptionModal.discount.title')}</h2>
      <StyledTable>
        <tbody>
          <StyledTr>
            <StyledTd>{getTranslationKey('overviewTab.item')}</StyledTd>
            <StyledTd>{getTranslationKey('overviewTab.price')}</StyledTd>
          </StyledTr>

          {coreAndBelongingAddonSubscriptions.map(
            ({ subscriptionType, amountCentsGross, currency }: any) => (
              <StyledTr key={subscriptionType}>
                <StyledTd>
                  {subscriptionType === 'core' && <CoreItem plan={subscription.plan} />}
                  {subscriptionType !== 'core' && <AddonItem type={subscriptionType} />}
                </StyledTd>
                <StyledTd>
                  {getTranslationKey('priceWithCurrency', {
                    price: getEuros(amountCentsGross),
                    currency: CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES]
                  })}
                </StyledTd>
              </StyledTr>
            )
          )}

          <TotalItem
            strikePrice
            subscriptions={coreAndBelongingAddonSubscriptions.map((sub: any) => ({
              ...sub,
              amountCentsDiscountedGross: sub.amountCentsGross
            }))}
          />
          <h2 className="mt-3 mb-0">
            {getTranslationKey('cancelSubscriptionModal.discount.discountedPrice')}
          </h2>
          <TotalItem
            subscriptions={coreAndBelongingAddonSubscriptions.map((sub: any) => ({
              ...sub,
              amountCentsDiscountedGross: sub.amountCentsGross * 0.7
            }))}
          />
        </tbody>
      </StyledTable>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="mt-3 mb-3">
        <Alert>
          {coreSubscriptionIntervall?.includes('yearly')
            ? getTranslationKey('cancelSubscriptionModal.discount.savingsInfoYearly', {
                amount: getEuros(savingsAmount),
                currency: CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES]
              })
            : getTranslationKey('cancelSubscriptionModal.discount.savingsInfoMonthly', {
                amount: getEuros(savingsAmount),
                totalSavingsAmount: getEuros(savingsAmount * 6),
                currency: CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES],
                totalSavingsAmountCurrency:
                  CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES]
              })}
        </Alert>
      </div>
      <Button
        disabled={isApplyingOffer}
        size="xl"
        color="success"
        onClick={() => {
          trackAction('clicked-action', {
            button_id: 'accept-churn-offer',
            placement: 'modal'
          });

          claimOffer();
        }}
        className="btn btn-primary w-100"
      >
        {isApplyingOffer
          ? getTranslationKey('cancelSubscriptionModal.discount.loadingOfferButton')
          : getTranslationKey('cancelSubscriptionModal.discount.acceptOfferButton')}
      </Button>
    </div>
  );
};

export default FourthStep;
