import { useCallback, useEffect, useReducer } from 'react';
import { PayoutMethodsForm, PayoutMethodsFormState } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPayoutAccountAction, updatePayoutAccountAction } from '../../modules/actions';
import { getPayoutAccountSelector } from '../../modules/selectors';
import { payoutMethodsReducer } from './payout-methods-reducer';
import { IPayoutAccount } from '../../modules/types/user';
import { IUpdatePayoutAccountParams } from '../../modules/types';
import { createPayoutAccount, updatePayoutAccount } from '../../modules/sagas/user';
import { canEnableIBAN, canEnablePayPal } from './common';

function _usePayouMethods(): Omit<PayoutMethodsForm, 'submit'> {
  const payoutAccount = useSelector(getPayoutAccountSelector);
  const [state, dispatch] = useReducer(payoutMethodsReducer, {
    paypal: {
      local: {
        payPalEnabled: false,
        payPalClientId: '',
        payPalSecret: ''
      },
      validation: {
        payPalEnabled: '',
        payPalClientId: '',
        payPalSecret: ''
      },
      ui: {
        edit: true,
        showErrors: false
      }
    },
    iban: {
      local: {
        ibanEnabled: false,
        iban: '',
        accountHolder: ''
      },
      validation: {
        ibanEnabled: '',
        iban: '',
        accountHolder: ''
      },
      ui: {
        edit: true,
        showErrors: false
      }
    }
  });

  const appDispatch = useDispatch();
  useEffect(() => {
    appDispatch(fetchPayoutAccountAction());
  }, []);

  return {
    payoutAccountId: payoutAccount.id || null,
    paypal: {
      ...state.paypal,
      remote: {
        payPalEnabled: payoutAccount.payPalEnabled,
        payPalClientId: payoutAccount.payPalClientId,
        payPalSecret: payoutAccount.payPalSecret
      },
      enable: () => {
        dispatch({
          type: 'changePayPalFieldValue',
          field: 'payPalEnabled',
          value: !state.paypal.local.payPalEnabled
        });
        if (!state.paypal.ui.edit) {
          appDispatch(
            updatePayoutAccountAction({
              id: payoutAccount.id,
              payPalEnabled: !payoutAccount.payPalEnabled
            })
          );
        }
      },
      enablingAllowed: () => true,
      submissionAllowed: () =>
        state.paypal.ui.edit &&
        !!state.paypal.local.payPalClientId &&
        !!state.paypal.local.payPalSecret,
      toggleEditMode: (edit?: boolean) => {
        dispatch({
          type: 'toggleEditMode',
          method: 'paypal',
          edit
        });
      },
      toggleErrors: (show?: boolean) => {
        dispatch({
          type: 'toggleErrors',
          method: 'paypal',
          show
        });
      },
      changeField: (field, value) => {
        dispatch({
          type: 'changePayPalFieldValue',
          field,
          value
        });
      },
      setFields: (fields) => {
        dispatch({
          type: 'setPayPalFields',
          ...fields
        });
      }
    },
    iban: {
      ...state.iban,
      remote: {
        ibanEnabled: payoutAccount.ibanEnabled,
        iban: payoutAccount.iban,
        accountHolder: payoutAccount.accountHolder
      },
      enable: () => {
        dispatch({
          type: 'changeIBANFieldValue',
          field: 'ibanEnabled',
          value: !state.iban.local.ibanEnabled
        });
        if (!state.iban.ui.edit) {
          appDispatch(
            updatePayoutAccountAction({
              id: payoutAccount.id,
              ibanEnabled: !payoutAccount.ibanEnabled
            })
          );
        }
      },
      enablingAllowed: () => true,
      submissionAllowed: () =>
        state.iban.ui.edit && !!state.iban.local.accountHolder && !!state.iban.local.iban,
      toggleEditMode: (edit?: boolean) => {
        dispatch({
          type: 'toggleEditMode',
          method: 'iban',
          edit
        });
      },
      toggleErrors: (show?: boolean) => {
        dispatch({
          type: 'toggleErrors',
          method: 'iban',
          show
        });
      },
      changeField: (field, value) => {
        dispatch({
          type: 'changeIBANFieldValue',
          field,
          value
        });
      },
      setFields: (fields) => {
        dispatch({
          type: 'setIBANFields',
          ...fields
        });
      }
    }
  };
}

export function usePayoutMethods(): PayoutMethodsForm {
  const appDispatch = useDispatch();
  const payoutMethods = _usePayouMethods();

  useEffect(() => {
    payoutMethods.paypal.setFields({
      payPalEnabled:
        payoutMethods.paypal.local.payPalEnabled || payoutMethods.paypal.remote.payPalEnabled,
      payPalClientId:
        payoutMethods.paypal.local.payPalClientId ||
        payoutMethods.paypal.remote.payPalClientId ||
        '',
      payPalSecret: '' // user must enter PayPal secret from scratch if they want to change it
    });
    payoutMethods.iban.setFields({
      ibanEnabled: payoutMethods.iban.local.ibanEnabled || payoutMethods.iban.remote.ibanEnabled,
      accountHolder:
        payoutMethods.iban.local.accountHolder || payoutMethods.iban.remote.accountHolder || '',
      iban: '' // the same rule applies here as for PayPal
    });

    if (!canEnablePayPal(payoutMethods.paypal.remote)) {
      payoutMethods.paypal.toggleEditMode(true);
    } else {
      payoutMethods.paypal.toggleEditMode(false);
    }

    if (!canEnableIBAN(payoutMethods.iban.remote)) {
      payoutMethods.iban.toggleEditMode(true);
    } else {
      payoutMethods.iban.toggleEditMode(false);
    }
  }, [
    payoutMethods.paypal.remote.payPalEnabled,
    payoutMethods.paypal.remote.payPalClientId,
    payoutMethods.paypal.remote.payPalSecret,
    payoutMethods.iban.remote.ibanEnabled,
    payoutMethods.iban.remote.accountHolder,
    payoutMethods.iban.remote.iban
  ]);

  const submit = useCallback(async () => {
    const update: Omit<IUpdatePayoutAccountParams, 'id'> = {};

    if (
      payoutMethods.paypal.ui.edit &&
      (payoutMethods.paypal.local.payPalClientId ||
        payoutMethods.paypal.local.payPalSecret ||
        payoutMethods.paypal.local.payPalEnabled)
    ) {
      payoutMethods.paypal.toggleErrors(true);
      const validationErrors = Object.values(payoutMethods.paypal.validation).filter(
        (err) => !!err
      );
      if (validationErrors.length > 0) return;

      update.payPalClientId = payoutMethods.paypal.local.payPalClientId;
      update.payPalSecret = payoutMethods.paypal.local.payPalSecret;
      update.payPalEnabled = payoutMethods.paypal.local.payPalEnabled;
    }

    if (
      payoutMethods.iban.ui.edit &&
      (payoutMethods.iban.local.ibanEnabled ||
        !!payoutMethods.iban.local.iban ||
        !!payoutMethods.iban.local.accountHolder)
    ) {
      payoutMethods.iban.toggleErrors(true);
      const validationErrors = Object.values(payoutMethods.iban.validation).filter((err) => !!err);
      if (validationErrors.length > 0) return;

      update.iban = payoutMethods.iban.local.iban;
      update.accountHolder = payoutMethods.iban.local.accountHolder;
      update.ibanEnabled = payoutMethods.iban.local.ibanEnabled;
    }

    let updatedOrCreatedPayoutAccount: IPayoutAccount;
    if (payoutMethods.payoutAccountId) {
      updatedOrCreatedPayoutAccount = await updatePayoutAccount(
        { id: payoutMethods.payoutAccountId, ...update },
        appDispatch
      );
    } else {
      updatedOrCreatedPayoutAccount = await createPayoutAccount(
        {
          iban: '',
          accountHolder: '',
          payPalClientId: '',
          payPalSecret: '',
          payPalEnabled: false,
          ibanEnabled: false,
          ...update
        },
        appDispatch
      );
    }

    if (canEnablePayPal(updatedOrCreatedPayoutAccount)) {
      payoutMethods.paypal.toggleEditMode(false);
    }

    if (canEnableIBAN(updatedOrCreatedPayoutAccount)) {
      payoutMethods.iban.toggleEditMode(false);
    }
  }, [payoutMethods]);

  return { ...payoutMethods, submit };
}

export function enablingAllowed(
  state: PayoutMethodsFormState,
  payoutAccount: IPayoutAccount,
  payoutMethod: 'paypal' | 'iban'
): boolean {
  if (payoutMethod === 'paypal') {
    return !(!!state.paypal.validation.payPalClientId || !!state.paypal.validation.payPalSecret);
  }
  if (payoutMethod === 'iban') {
    return !(
      (!state.iban.local.iban && !payoutAccount.iban) ||
      !!state.iban.validation.accountHolder
    );
  }
}
