import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { FaRegQuestionCircle } from 'react-icons/fa';

const StyledLink = styled(Button)`
  color: #5d5d5d;
  font-size: 12px;
  :hover {
    color: #5d5d5d;
  }
`;
export const HelpLink = ({ href, children }: { href: string; children: any }) => (
  <StyledLink color="link" href={href} target="_blank">
    {children} <FaRegQuestionCircle style={{ fontSize: '18px', marginLeft: '5px' }} />
  </StyledLink>
);
