import React, { useEffect, useState } from 'react';
import { getTranslationKey } from '../../modules/utils';
import { Button, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { getUser } from '../../modules/selectors';
import { FiEditBtn, Title, useUserHasPayinPaymentMethod } from './common';
import { StripeElements } from '../../components/Stripe/StripeElements';
import {
  StripePaymentElement,
  usePaymentMethodSetup
} from '../../components/Stripe/StripePaymentElement';
import { fetchShopPayinPaymentMethod } from '../../modules/actions';
import { isBillingAddressFilled } from '../../common/is-billing-address-filled';
import { CardTitle, DisplayCard, Header } from './DisplayCard';
import { SlPaypal } from 'react-icons/sl';
import { VscCreditCard } from 'react-icons/vsc';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';

function PaymentElement({ onCancel }: { onCancel?: () => void }) {
  const user = useSelector(getUser);
  const { address: billingAddress } = useSelector(getPaymentInfo);
  const userHasPaymentMethod = useUserHasPayinPaymentMethod();

  const { confirmSetup, confirming, errorMessage } = usePaymentMethodSetup({
    returnURLFail: `${user.builderDomain}/#/shop/payment-settings`,
    returnURLSuccess: `${user.builderDomain}/#/shop/payment-settings?is_setup=true`,
    metadata: {
      shop_payin: true
    }
  });
  const billingAddressIsFilled = isBillingAddressFilled(billingAddress);

  return (
    <>
      <StripePaymentElement errorMessage={errorMessage} />
      <Button
        color="primary"
        className="px-5 mt-3 text-uppercase"
        disabled={!billingAddressIsFilled || confirming}
        type="submit"
        onClick={confirmSetup}
      >
        {confirming ? getTranslationKey('buttonLoading') : getTranslationKey('save')}
      </Button>
      {userHasPaymentMethod && (
        <Button color="secondary" className="px-5 mt-3 ml-2 text-uppercase" onClick={onCancel}>
          {getTranslationKey('messages.cancel')}
        </Button>
      )}
    </>
  );
}

type PaymentMethodNameProps = {
  type: 'card' | 'paypal' | 'sepa_debit';
  brand: string | null;
};
function PaymentMethod(props: PaymentMethodNameProps) {
  const { type, brand } = props;

  let CardIcon;
  switch (brand) {
    case 'visa':
      CardIcon = FaCcVisa;
      break;
    case 'mastercard':
      CardIcon = FaCcMastercard;
      break;
    default:
      CardIcon = VscCreditCard;
  }

  return (
    <>
      {type === 'card' && (
        <CardTitle>
          <CardIcon size={24} color="#1d1d1d" className="mr-2" />
          <span className="title">
            {brand && <span className="mr-2">{brand.toUpperCase()}</span>}
            <span>Card</span>
          </span>
        </CardTitle>
      )}
      {type === 'paypal' && (
        <CardTitle>
          <SlPaypal size={22} color="#1d1d1d" className="mr-2" />
          <span className="title mr-1">PayPal</span>
        </CardTitle>
      )}
      {type === 'sepa_debit' && (
        <CardTitle>
          <VscCreditCard size={24} color="#1d1d1d" className="mr-2" />
          <span className="title mr-1">SEPA Debit</span>
        </CardTitle>
      )}
    </>
  );
}

function PayinPaymentMethodTabContent() {
  const { shopPayinPaymentMethod } = useSelector(getPaymentInfo);
  const [showSetupForm, setShowSetupForm] = useState(false);
  const userHasPaymentMethod = useUserHasPayinPaymentMethod();

  return (
    <div className="animated fadeIn">
      {userHasPaymentMethod !== true || showSetupForm ? (
        <StripeElements mode="setup">
          <PaymentElement onCancel={() => setShowSetupForm(false)} />
        </StripeElements>
      ) : (
        <DisplayCard className="animated fadeIn">
          <Header>
            <div>
              <PaymentMethod
                type={shopPayinPaymentMethod.type}
                brand={shopPayinPaymentMethod.card?.brand}
              />
            </div>
            <FiEditBtn size={20} onClick={() => setShowSetupForm(true)} />
          </Header>
          <div>
            {shopPayinPaymentMethod.type === 'card' && (
              <div>**** **** **** {shopPayinPaymentMethod.card?.last4}</div>
            )}
            {shopPayinPaymentMethod.type === 'paypal' && (
              <div>{shopPayinPaymentMethod.paypal?.email}</div>
            )}
            {shopPayinPaymentMethod.type === 'sepa_debit' && (
              <div>
                {shopPayinPaymentMethod.sepa_debit?.country} ****************
                {shopPayinPaymentMethod.sepa_debit?.last4}
              </div>
            )}
          </div>
        </DisplayCard>
      )}
    </div>
  );
}

export function PayinPaymentMethodTab() {
  const dispatch = useDispatch();
  const { shopPayinPaymentMethod } = useSelector(getPaymentInfo);

  useEffect(() => {
    dispatch(fetchShopPayinPaymentMethod());
  }, []);

  return (
    <div>
      <Title className="mb-4">
        {getTranslationKey('shop.paymentSettings.payinPaymentMethodTitle')}
      </Title>
      <p className="mb-5" style={{ width: '60%' }}>
        {getTranslationKey('shop.paymentSettings.payinPaymentMethodInfo')}
      </p>

      <Col md={4} className="p-0 m-0">
        {shopPayinPaymentMethod.loaded && <PayinPaymentMethodTabContent />}
      </Col>
    </div>
  );
}
