import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';

export const Title = styled.h3`
  font-weight: 300;
  color: #1d1d1d;
`;

export const FiEditBtn = styled(FiEdit)`
  cursor: pointer;
`;

export function canEnablePayPal(payPal: { payPalClientId: string; payPalSecret: string }): boolean {
  return !!payPal.payPalClientId && !!payPal.payPalSecret;
}

export function canEnableIBAN(iban: { iban: string; accountHolder: string }): boolean {
  return !!iban.iban && !!iban.accountHolder;
}

export function useUserHasPayinPaymentMethod(): boolean {
  const { shopPayinPaymentMethod } = useSelector(getPaymentInfo);
  return !!shopPayinPaymentMethod?.type;
}
