import React, { useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'querystring';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { getCountriesList } from '../../modules/selectors/countries';
import { fetchCountriesAction } from '../../modules/actions/countries';
import { getUser } from '../../modules/selectors';
import { useLocation } from 'react-router';
import { Api, getTranslationKey } from '../../modules/utils';

export type StripePaymentElementProps = {
  errorMessage?: string;
};

/**
 * Must be used inside element wrapped with stripe's <Elements> component
 */
export const StripePaymentElement = ({ errorMessage }: StripePaymentElementProps) => {
  const dispatch = useDispatch();

  const { address: billingAddress } = useSelector(getPaymentInfo);
  const countriesList = useSelector(getCountriesList);
  const billingCountry = countriesList.find((country) => country.id === billingAddress.country);

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  return (
    <div>
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              name: billingAddress.firstName + ' ' + (billingAddress.lastName || ''),
              email: billingAddress.email,
              address: {
                country: billingCountry?.code,
                postal_code: billingAddress.zipcode,
                city: billingAddress.city,
                line1: billingAddress.company,
                line2: `${billingAddress.street} ${billingAddress.streetNumber}`
              }
            }
          },
          fields: {
            billingDetails: {
              name: 'never',
              email: 'never',
              address: 'never'
            }
          }
        }}
      />
      {errorMessage && <div className="color-danger">{errorMessage}</div>}
    </div>
  );
};

export function useIsSetup() {
  const location = useLocation();
  const searchParams = qs.parse(location.search.substring(1));

  return searchParams.is_setup === 'true';
}

export function useIsSetupFailed() {
  const isSetup = useIsSetup();
  const location = useLocation();
  const searchParams = qs.parse(location.search.substring(1));
  const setupFailed = isSetup && searchParams.setup_failed === 'true';

  return setupFailed;
}

export type PaymentMethodSetupParams = {
  /** Where the user should be redirected if setup has failed */
  returnURLFail: string;
  /** Where the user should be redirected if setup was successful */
  returnURLSuccess: string;
  /** Payment method metadata */
  metadata?: any;
};

/**
 * Hook for payment method setup
 * Must be used inside element wrapped with stripe's <Elements> component
 */
export function usePaymentMethodSetup(params: PaymentMethodSetupParams) {
  const dispatch = useDispatch();
  const elements = useElements();
  const user = useSelector(getUser);
  const { address: billingAddress } = useSelector(getPaymentInfo);
  const stripe = useStripe();
  const location = useLocation();
  const searchParams = qs.parse(location.search.substring(1));
  const countriesList = useSelector(getCountriesList);
  const billingCountry = countriesList.find((country) => country.id === billingAddress.country);
  const [confirming, setConfirming] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  const isSetupFailed = useIsSetupFailed();
  useEffect(() => {
    if (isSetupFailed) {
      setErrorMessage(getTranslationKey('shop.settings.paymentMethodSetupFailed'));
    }
  }, [isSetupFailed]);

  const handleError = (error: any) => {
    setConfirming(false);
    setErrorMessage(error.message);
  };

  const confirmSetup = async () => {
    setConfirming(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);

      return;
    }

    let clientSecret =
      (searchParams.client_secret as string) ||
      user.subscription?.stripe?.paymentSetup?.clientSecret;
    let returnURL =
      (searchParams.return_url as string) || user.subscription?.stripe?.paymentSetup?.returnUrl;
    let returnURLFail =
      (searchParams.return_url_fail as string) ||
      user.subscription?.stripe?.paymentSetup?.returnUrlFail;

    if (!clientSecret) {
      const res = await Api.Payment.initiateSetupIntent();
      clientSecret = res.result.clientSecret;
      returnURL = params.returnURLSuccess;
      returnURLFail = params.returnURLFail;
    }

    const billingDetails = {
      name: billingAddress.firstName + ' ' + (billingAddress.lastName || ''),
      email: billingAddress.email,
      address: {
        country: billingCountry?.code,
        postal_code: billingAddress.zipcode,
        state: '',
        city: billingAddress.city,
        line1: billingAddress.company,
        line2: `${billingAddress.street} ${billingAddress.streetNumber}`
      }
    };
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        // if payment setup requires going to a different page
        // Stripe will redirect the user to this URL which points to our backend endpoint
        return_url: `${window.API_URL}/api/subscription/stripe-payment-complete?${qs.stringify({
          builder_domain: user.builderDomain,
          return_url: returnURL,
          return_url_fail: returnURLFail,
          is_setup: true
        })}`,
        payment_method_data: {
          billing_details: billingDetails,
          // payment_method_data.metadata is not defined in Stripe types but is supported by API.
          // Read more: https://docs.stripe.com/api/setup_intents/confirm#confirm_setup_intent-payment_method_data-metadata
          // @ts-expect-error
          metadata: params.metadata
        }
      }
    });

    if (error) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      handleError(error);
    }
  };

  return {
    confirmSetup,
    confirming,
    errorMessage
  };
}
