import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader as _CardHeader, CardTitle } from '../components/NewDesign/Card';
import { HelpLink } from '../components/NewDesign/HelpLink';
import { getTranslationKey } from '../modules/utils';
import { Alert } from '../components/NewDesign/Alert';
import { Spacer } from '../components/NewDesign/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import { getPayoutAccountSelector } from '../modules/selectors';
import {
  fetchPaymentInfoAction,
  fetchPayoutAccountAction,
  fetchShopPayinPaymentMethod
} from '../modules/actions';
import { Tabs } from '../components';
import { BillingAddressTab } from './ShopPaymentSettings/BillingAddressTab';
import { PayinPaymentMethodTab } from './ShopPaymentSettings/PayinPaymentMethodTab';
import { useIsSetup } from '../components/Stripe/StripePaymentElement';
import { PayoutMethodsTab } from './ShopPaymentSettings/PayoutMethodsTab';
import { isBillingAddressFilled } from '../common/is-billing-address-filled';
import { getPaymentInfo } from '../modules/selectors/paymentInfo';
import { useUserHasPayinPaymentMethod } from './ShopPaymentSettings/common';
import styled from 'styled-components';

const TABS = [
  {
    id: 'payout-methods',
    label: getTranslationKey('shop.paymentSettings.payoutMethods'),
    component: () => (
      <CardBody padded className="animated fadeIn">
        <PayoutMethodsTab />
      </CardBody>
    )
  },
  {
    id: 'billing-address',
    label: getTranslationKey('shop.paymentSettings.billingAddress'),
    component: () => (
      <CardBody padded className="animated fadeIn">
        <BillingAddressTab />
      </CardBody>
    )
  },
  {
    id: 'payin-payment-method',
    label: getTranslationKey('shop.paymentSettings.payinPaymentMethod'),
    component: () => (
      <CardBody padded className="animated fadeIn">
        <PayinPaymentMethodTab />
      </CardBody>
    )
  }
];

const CardHeader = styled(_CardHeader)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ShopPaymentSettings = () => {
  const dispatch = useDispatch();
  const payoutAccount = useSelector(getPayoutAccountSelector);
  const { address: billingAddress } = useSelector(getPaymentInfo);

  const userHasPaymentMethod = useUserHasPayinPaymentMethod();
  const isPayoutMethodProvided =
    (payoutAccount.ibanEnabled && payoutAccount.iban && payoutAccount.accountHolder) ||
    (payoutAccount.payPalEnabled && payoutAccount.payPalClientId && payoutAccount.payPalSecret);
  const isShopPaymentSetup =
    isBillingAddressFilled(billingAddress) && isPayoutMethodProvided && userHasPaymentMethod;
  const isSetup = useIsSetup();

  useEffect(() => {
    dispatch(fetchPayoutAccountAction());
    dispatch(fetchShopPayinPaymentMethod());
    dispatch(fetchPaymentInfoAction());
  }, []); // eslint-disable-line

  return (
    <div>
      <Card padded={false}>
        <CardHeader padded className="mb-0">
          <CardTitle>
            {getTranslationKey('shop.paymentSettings.pageTitle')}
            {/* TODO: Change help link */}
            <HelpLink href="https://support.scrappbook.de/de/collections/11591-preise">
              Get Help{' '}
            </HelpLink>
          </CardTitle>
          <div className="mt-4" style={{ width: '60%' }}>
            {getTranslationKey('shop.paymentSettings.intro')}
          </div>
          <Spacer mid />
          <Alert
            // TODO: wait for payout account do load if there is one
            isOpen={!isShopPaymentSetup}
            dismissible={false}
            color="danger"
            className="animated fadeIn"
          >
            {getTranslationKey('shop.paymentSettings.alert')}
          </Alert>
        </CardHeader>
        <Tabs
          tabs={TABS}
          newDesign
          defaultActiveTab={isSetup ? 'payin-payment-method' : 'payout-methods'}
        />
      </Card>
    </div>
  );
};
