import React, { useState } from 'react';
import styled from 'styled-components';
import { FiEditBtn } from './common';
import { SwitchItem } from '../../components';
import { Header } from './DisplayCard';

function getCardBackgroundColor(edit: boolean, enabled: boolean) {
  if (enabled && edit) return 'transparent';
  if (enabled && !edit) return '#fafafa';
  if (!enabled) return '#f9f9f9';
}
const Wrapper = styled.div<{ enabled: boolean; edit: boolean }>`
  border: ${(props) =>
    props.enabled && props.edit ? '1px solid #d6d6d6' : '1px solid transparent'};
  border-radius: 22px;
  background: ${(props) => getCardBackgroundColor(props.edit, props.enabled)};
  opacity: ${(props) => (props.enabled ? '1' : '0.6')};
  transition: opacity 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
  padding: 1.5rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type PayoutMethodCardProps = {
  enabled: boolean;
  toggle: () => void;
  edit: boolean;
  setEdit: (edit: boolean) => void;
  enablingDisabled?: boolean;
  title: JSX.Element;
  form: JSX.Element;
  view: (props: { background: string }) => JSX.Element;
};

export const PayoutMethodCardForm = ({
  enabled,
  toggle,
  edit,
  setEdit,
  enablingDisabled,
  title,
  form,
  view: View
}: PayoutMethodCardProps) => (
  <Wrapper enabled={enabled} edit={edit}>
    <Header>
      {title}
      <Actions>
        {!edit && <FiEditBtn size={20} onClick={() => setEdit(true)} className="mr-3" />}
        <SwitchItem
          value={enabled}
          changeCallback={toggle}
          isDisabled={enablingDisabled}
          margin="0"
          switcherMargin="0"
          direction="col"
          outline
        />
      </Actions>
    </Header>
    {edit ? form : <View background={getCardBackgroundColor(edit, enabled)} />}
  </Wrapper>
);

export function useCardForms(nForms: number): Array<{
  edit: boolean;
  setEdit: (edit: boolean) => void;
}> {
  const [cardStates, setCardStates] = useState<boolean[]>(Array(nForms).fill(false));

  return cardStates.map((edit, cardId) => ({
    edit,
    setEdit: (edit: boolean) =>
      setCardStates((prev) => prev.map((currEdit, idx) => (idx === cardId ? edit : currEdit)))
  }));
}
