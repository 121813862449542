import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTranslationKey } from '../../../../../../modules/utils/helpers';
import {
  SubscriptionType,
  IRestartSubscriptionActionSource
} from '../../../../../../modules/types/subscriptions';
import { restartSubscription } from '../../../../../../modules/actions';

interface IRenewalLinkProps {
  subscriptionType: SubscriptionType;
  nextPaymentAt: string;
}

const RenewalLink = ({ subscriptionType, nextPaymentAt }: IRenewalLinkProps) => {
  const dispatch = useDispatch();

  const renewSubscription = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(
      restartSubscription({
        subscriptionType,
        actionSource: IRestartSubscriptionActionSource.OverviewSub
      })
    );
  };

  const isSubscriptionTimedOut = new Date(nextPaymentAt).getTime() - new Date().getTime() <= 0;

  if (isSubscriptionTimedOut) {
    return null;
  }

  return (
    <Link to="/" onClick={renewSubscription}>
      {getTranslationKey('overviewTab.restartSubscription')}
    </Link>
  );
};

export default RenewalLink;
