import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModalData,
  getModalsStates,
  getPresetByIdSelector,
  getUser
} from '../../modules/selectors';
import {
  changeModalState,
  fetchDomains,
  fetchTemplates,
  updatePresetAction
} from '../../modules/actions';
import { EditableTitleField } from '../../components/EditableTitleField';
import {
  DEFAULT_PRESET_ITEM,
  DEFAULT_PRESET_TAB,
  PRESET_EDIT_TABS
} from '../../modules/constants/presets';
import styled from 'styled-components';
import { TabsHeader as Tabs } from '../../components/Tabs/TabsHeader';
import TabContentComponent from '../../components/Tabs/TabContentComponent';
import { PresetSetting } from './PresetSetting';
import { IPreset } from '../../modules/reducers/presets';
import { PresetTracking } from './PresetTracking';
import { PresetGalleries } from './PresetGalleries';
import { PresetAutoMarketing } from './PresetAutoMarketing';
import { PresetDesign } from './PresetDesign';
import { get } from 'lodash';
import { fetchMessagesAction } from '../../modules/actions/message';

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const SaveButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    min-width: 30%;
  }
`;

// tslint:disable-next-line:max-func-body-length
export const EditPresetModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { presetsSettingModal } = useSelector(getModalsStates);
  const modalData = useSelector(getModalData);
  const preset = useSelector(getPresetByIdSelector)(modalData.presetId) || {
    ...DEFAULT_PRESET_ITEM
  };
  const [presetName, changePresetName] = useState('');
  const [presetState, changePresetState] = useState({ ...DEFAULT_PRESET_ITEM });
  const [activeTab, setActiveTab] = useState(DEFAULT_PRESET_TAB);
  const isShopFeatureHidden =
    user?.type === 'VST' || get(user, 'whiteLabel.hideElements', []).includes('shop');

  useEffect(() => {
    dispatch(fetchDomains());
    dispatch(fetchTemplates());
    dispatch(fetchMessagesAction());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (preset._id.length) {
      changePresetName(preset.name);
      changePresetState(preset);
    }
  }, [preset]); // eslint-disable-line

  const closeModal = () => {
    dispatch(
      changeModalState({
        key: 'presetsSettingModal',
        state: false,
        modalData: {}
      })
    );
  };

  const changePresetNameHandler = (value: string) => changePresetName(value);

  const changeSettingsCallback = (data: Partial<IPreset>) => {
    changePresetState((prevState) => ({ ...prevState, ...data }));
  };

  const updatePresetHandler = () => {
    dispatch(
      updatePresetAction({
        ...presetState,
        name: presetName
      })
    );
    closeModal();
  };

  const tabsList = PRESET_EDIT_TABS.map((item) => ({
    ...item,
    label: getTranslationKey(`${item.label}`),
    isHidden: isShopFeatureHidden
  }));

  const tabsComponents = [
    {
      id: 'settings',
      component: PresetSetting,
      componentProps: {
        preset: presetState,
        onChangeCallback: changeSettingsCallback
      }
    },
    {
      id: 'album-defaults',
      component: PresetGalleries,
      componentProps: {
        preset: presetState,
        onChangeCallback: changeSettingsCallback
      }
    },
    {
      id: 'tacking',
      component: PresetTracking,
      componentProps: {
        preset: presetState,
        onChangeCallback: changeSettingsCallback
      }
    },
    {
      id: 'auto-marketing',
      component: PresetAutoMarketing,
      componentProps: {
        preset: presetState,
        onChangeCallback: changeSettingsCallback
      }
    },
    {
      id: 'design',
      component: PresetDesign,
      componentProps: {
        preset: presetState,
        onChangeCallback: changeSettingsCallback
      }
    }
  ].filter(
    (tab) =>
      (tab.id === 'auto-marketing' && !get(user, 'whiteLabel.hideElements', []).includes('shop')) ||
      tab.id !== 'auto-marketing'
  );

  return (
    <Modal
      centered
      isOpen={presetsSettingModal}
      toggle={closeModal}
      wrapClassName="modal-dark"
      size="lg"
    >
      <ModalHeader toggle={closeModal}>
        <EditableTitleField
          text={presetName}
          submitChangedCallBack={changePresetNameHandler}
          themeName="white"
        />
      </ModalHeader>
      <ModalBody>
        <TabsWrapper>
          <Tabs tabsList={tabsList} activeTab={activeTab} onTabClick={setActiveTab} />
        </TabsWrapper>
        <TabContentComponent
          activeTab={activeTab}
          contentList={tabsComponents}
          height="auto"
          isRemoveOverflow={false}
        />
      </ModalBody>
      <ModalFooter>
        <SaveButtonBlock>
          <Button
            size="md"
            color="success"
            onClick={updatePresetHandler}
            className="btn btn-primary"
          >
            {getTranslationKey('galleryPresets.saveButton')}
          </Button>
        </SaveButtonBlock>
      </ModalFooter>
    </Modal>
  );
};
