import React from 'react';
import { ITab, useTabs } from './useTabs';
import { TabsHeader } from './TabsHeader';
import TabContentComponent from './TabContentComponent';

export interface ITabsProps {
  tabs: ITab[];
  defaultActiveTab?: string;
  isSortable?: boolean;
  newDesign?: boolean;
}

export const Tabs = (props: ITabsProps) => {
  const { tabs, activeTab, toggle } = useTabs({
    tabs: props.tabs,
    defaultActiveTab: props.defaultActiveTab
  });

  return (
    <>
      <TabsHeader
        tabsList={tabs}
        activeTab={activeTab}
        onTabClick={toggle}
        isSortable={props.isSortable || false}
        newDesign={props.newDesign}
      />
      <TabContentComponent activeTab={activeTab} contentList={tabs} />
    </>
  );
};
