import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { VscChevronDown } from 'react-icons/vsc';

export default ({
  input,
  field: { title, tooltip_id, tooltip_text, options, labelClass },
  meta: { touched, error },
  disabled
}) => (
  <div className="form-group">
    {title && (
      <label className={`${labelClass} form-control-label mb-2`}>
        {title}
        {tooltip_id && tooltip_text && (
          <i id={tooltip_id} className="m-l-5 fa fa-icon fa-question-circle">
            <UncontrolledTooltip placement="right" target={tooltip_id}>
              {tooltip_text}
            </UncontrolledTooltip>
          </i>
        )}
      </label>
    )}
    <div className="select-wrapper">
      <select
        {...input}
        disabled={disabled}
        className={touched && error ? 'form-control is-invalid' : 'form-control'}
      >
        {options.map((option, index) => (
          <option disabled={option.disabled} value={option.value} key={index}>
            {option.displayValue}
          </option>
        ))}
      </select>
      <VscChevronDown size={12} className="dropdown-icon" />
    </div>
    {touched &&
      error &&
      error.map((error, i) => (
        <div key={i} data-cy="invalid-feedback-forms" className="invalid-feedback">
          {error}
        </div>
      ))}
  </div>
);
// position: absolute;
//     width: 80px;
//     height: 47px;
//     top: 0;
//     content: '';
//     left: 0;
//     /* display: block; */
//     border: 1px solid red;
//     z-index: 100;
