import React, { ReactNode } from 'react';
import { StyledTr, StyledTd } from '../../../index';
import { SubscriptionType } from '../../../../../../modules/types/subscriptions';
import styled from 'styled-components';

interface IPaymentMethodProps {
  isMultipleSubscriptions: boolean;
  isLastElement: boolean;
  subscriptionType: SubscriptionType;
  renewUrl?: string;
  headingTitle: string;
  extraOptionTitle: string;
  customLink?: ReactNode;
  disableDefaultLink?: boolean;
  onLinkClick?: () => void;
}

const ScrappbookPlan = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

const PlanTitle = styled.span`
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
`;

const PaymentItem = ({
  isMultipleSubscriptions,
  isLastElement,
  subscriptionType,
  renewUrl,
  headingTitle,
  extraOptionTitle,
  customLink,
  disableDefaultLink,
  onLinkClick
}: IPaymentMethodProps) => {
  const isCore = subscriptionType === 'core';
  customLink =
    customLink && onLinkClick ? <div onClick={onLinkClick}>{customLink}</div> : customLink;

  return (
    <StyledTr disableLine={isMultipleSubscriptions && !isLastElement}>
      <StyledTd paddingTop={0}>
        {isCore && isMultipleSubscriptions && <ScrappbookPlan>scrappbook</ScrappbookPlan>}
        {!isCore && <PlanTitle data-cy="PlanTitle">{subscriptionType}</PlanTitle>}

        {customLink ||
          (!disableDefaultLink && (
            <a
              href={renewUrl || '/'}
              onClick={onLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              {headingTitle}
            </a>
          ))}
      </StyledTd>

      {isMultipleSubscriptions && <StyledTd paddingTop={0}>{extraOptionTitle}</StyledTd>}
      {!isMultipleSubscriptions && <StyledTd paddingTop={0} />}
    </StyledTr>
  );
};

export default PaymentItem;
