import React from 'react';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getUser } from '../../modules/selectors';
import { useSelector } from 'react-redux';
import { usePaymentMethods } from '../../modules/types/subscriptions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

type StripeElementProps = {
  children: React.ReactNode;
  mode?: 'setup' | 'subscription';
  amount?: number;
  currency?: string;
  country?: string;
};

export const StripeElements = (props: StripeElementProps) => {
  const user = useSelector(getUser);
  const { currency, paymentMethodTypes } = usePaymentMethods(props.country, props.currency);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: props.mode ? 'setup' : 'subscription',
        amount: props.amount,
        currency,
        paymentMethodCreation: props.mode === 'setup' ? 'manual' : undefined,
        locale: ['de', 'en'][user.languageSoftware] as StripeElementLocale,
        payment_method_types: paymentMethodTypes
      }}
    >
      {props.children}
    </Elements>
  );
};
