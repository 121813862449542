import React from 'react';
import useModalHelpers from '../../../../../../../components/CancelSubscriptionModal/useModalHelpers';
import { SubscriptionType } from '../../../../../../../modules/types/subscriptions';
import { getTranslationKey } from '../../../../../../../modules/utils/helpers';
import { Link } from 'react-router-dom';

const CancelLink = ({ subscriptionType }: { subscriptionType: SubscriptionType }) => {
  const { openModal } = useModalHelpers();

  const cancelSubscription = (e: React.MouseEvent) => {
    e.preventDefault();
    // @ts-ignore
    openModal(subscriptionType);
  };

  return (
    <Link to="/" onClick={cancelSubscription}>
      {getTranslationKey('overviewTab.cancelSubscription')}
    </Link>
  );
};

export default CancelLink;
