import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import { partial } from 'lodash';
import theme from '../../assets/css/theme';
import { CARD_PADDING } from '../NewDesign/Card';

const NavItemStyled = styled(NavItem)`
  &:has(.active) {
    ${(props) =>
      props.newDesign &&
      `border-bottom: 3px solid ${theme.commonColors.second};
      margin-bottom: -3px;`}
  }

  &:first-child {
    ${(props) => props.newDesign && `margin-left: ${CARD_PADDING}px;`}
  }

  &:not(:first-child) {
    ${(props) => props.newDesign && 'margin-left: 3rem;'}
  }
`;

const NavLinkStyled = styled(NavLink)`
  text-transform: capitalize;
  background-color: transparent;
  color: ${theme.textColors.baseText};
  padding: 0.5rem;
  border: none !important;
  &.nav-link.active {
    ${(props) => (props.newDesign ? 'font-weight: 400;' : 'font-weight: bold;')}
    background-color: transparent;
    color: ${theme.commonColors.second};
  }
  ${(props) =>
    props.newDesign &&
    `color: #ACACAC;
    font-size: 1.25rem;
    font-weight: 300;`}
`;

interface IProps {
  name: string;
  onClick: (name: string) => void;
  isActive: boolean;
  className?: string;
  newDesign?: boolean;
}

export const TabItem = ({ name, onClick, isActive, className, newDesign }: IProps) => {
  if (isActive) className = `active ${className}`;

  return (
    <NavItemStyled newDesign={newDesign}>
      <NavLinkStyled onClick={partial(onClick, name)} className={className} newDesign={newDesign}>
        {name}
      </NavLinkStyled>
    </NavItemStyled>
  );
};

export default TabItem;
