import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import useTrackPageView from '../../hooks/tracking/use-track-page-view';
import { useIsPaymentProblem } from '../../common/use-is-payment-problem';
import qs from 'querystring';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { useSelector } from 'react-redux';

interface IPaymentProblems {
  [key: string]: { [key: string]: readonly string[] };
}

const allowedRoutesMap: IPaymentProblems = {
  default: {
    paymentproblem: Object.freeze(['/subscription', '/subscription/pricing/payment']),
    'chargeback-fee-unpaid': Object.freeze(['/subscription'])
  },
  stripe: {
    paymentproblem: Object.freeze(['/subscription']),
    'chargeback-fee-unpaid': Object.freeze(['/subscription'])
  },
  digistore: {
    paymentproblem: Object.freeze(['/subscription', '/subscription/pricing/payment']),
    'chargeback-fee-unpaid': Object.freeze(['/subscription'])
  }
};

const SubscriptionRoute = (props: RouteProps & { pageId: string }) => {
  const history = useHistory();
  const { paymentProvider } = useSelector(getPaymentInfo);
  const { isPaymentProblem, state } = useIsPaymentProblem();
  const {
    location: { pathname, search }
  } = history;
  const searchParams = qs.parse(search.substring(1));

  const trackPageView = useTrackPageView();
  useEffect(() => {
    trackPageView(props.pageId, props.path as string);
  }, []); // eslint-disable-line

  if (
    (state === 'payment_setup_problem' || searchParams.setup_failed) &&
    searchParams.is_setup !== 'true' &&
    pathname !== '/subscription/pricing/payment'
  ) {
    let queryString = '';
    if (searchParams.setup_failed) {
      queryString = search;
    }

    history.push('/subscription/pricing/payment' + queryString);

    return null;
  }

  const allowedRoutes =
    (allowedRoutesMap[paymentProvider] || allowedRoutesMap.default)[state] || [];
  const notAllowedRoutes = !allowedRoutes.includes(pathname);

  if (isPaymentProblem && notAllowedRoutes) {
    history.push('/subscription');

    return null;
  }

  return <Route {...props} />;
};

export default SubscriptionRoute;
