import { setCookie } from '../helpers/cookies';
import Texts from '../json/texts.json';
import { get as _get } from 'lodash';
import { getTranslationKey } from '../modules/utils';

function get(user) {
  try {
    const routes = {
      items: [
        {
          name: getTranslationKey('userDashboard.title'),
          url: '/dashboard',
          icon: 'icon-graph'
        },
        {
          name: getTranslationKey('myCollections'),
          url: '/collections',
          icon: 'icon-grid',
          attributes: {
            className: 'nav-link nav-dropdown-toggle',
            'data-cy': 'collections-dropdown'
          }
        },
        {
          name: getTranslationKey('portfolio.portfolios'),
          url: '/portfolios',
          icon: 'icon-folder-alt',
          attributes: {
            className: 'nav-link nav-dropdown-toggle',
            'data-cy': 'collections-dropdown'
          }
        },
        {
          name: getTranslationKey('settings'),
          url: '/settings',
          icon: 'icon-settings',
          attributes: {
            className: 'help_setupBrand_menu nav-link nav-dropdown-toggle'
          }
        },
        {
          name: getTranslationKey('shop.shopMenuLabel'),
          url: '/shop',
          icon: 'icon-basket',
          attributes: {
            className: 'nav-link nav-dropdown-toggle'
          },
          children: [
            {
              name: getTranslationKey('shop.shopMenuLabel'),
              url: '/shop',
              icon: 'icon-wallet'
            },
            {
              name: getTranslationKey('shop.paymentSettings.pageTitle'),
              url: '/shop/payment-settings',
              icon: 'icon-wallet'
            },
            {
              name: getTranslationKey('shop.priceMenuLabel'),
              url: '/shop/newPrices',
              icon: 'icon-basket'
            },
            {
              name: getTranslationKey('shop.priceMenuLabelOld'),
              url: '/shop/prices',
              icon: 'icon-basket'
            },
            {
              name: getTranslationKey('shop.digitalPricingListLabel'),
              url: '/shop/digital-pricing-list',
              icon: 'icon-cloud-download'
            },
            {
              name: getTranslationKey('shop.couponsMenuLabel'),
              url: '/shop/coupons',
              icon: 'icon-tag'
            },
            {
              name: getTranslationKey('shop.ordersMenuLabel'),
              url: '/shop/orders',
              icon: 'icon-list'
            },
            {
              name: Texts[window.LANGUAGE_SW].shop['sales-automation'].title,
              url: '/shop/sales-automation',
              icon: 'icon-loop'
            }
          ]
        },
        {
          name: getTranslationKey('accountData'),
          url: '/account',
          icon: 'icon-user'
        }
      ]
    };

    if (
      (user.type === 'VST' && !_get(user, 'additionalFeatures', []).includes('shop')) ||
      _get(user, 'whiteLabel.hideElements', []).includes('shop')
    ) {
      routes.items.splice(4, 1);
    }

    if (user.type === 'SB') {
      const items = [
        {
          name: getTranslationKey('subscriptionLabel'),
          icon: 'icon-diamond',
          url: '/subscription'
        },
        {
          name: getTranslationKey('referralProgram.menu'),
          icon: 'icon-paper-plane',
          url: '/referral'
        },
        {
          name: getTranslationKey('changelogMenuLabel'),
          icon: 'icon-bell',
          attributes: {
            'data-canny-changelog': '',
            className: 'nav-link never-active',
            onClick(e) {
              e.preventDefault();
            }
          }
        }
      ];
      routes.items.push(...items);
    }

    routes.items.push({
      name: getTranslationKey('logout'),
      icon: 'icon-logout',
      attributes: {
        className: 'nav-link never-active',
        onClick() {
          if (typeof window.analytics.reset === 'function') {
            window.analytics.reset();
          }

          window.localStorage.removeItem('auth');
          window.localStorage.removeItem('subscriptionConfig');
          setCookie('rescrictionModalClosed', '');

          if (_get(window, 'Intercom.booted')) {
            window.Intercom('shutdown');
          }

          window.location = window.API_URL + '/api/logout';
        }
      }
    });

    return routes;
  } catch (e) {
    console.log(e);

    return {};
  }
}

export default get;
