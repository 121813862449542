import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale, loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import media from '../../assets/css/media';
import theme from '../../assets/css/theme';
import { useSubscriptionCurrency } from '../../modules/hooks/useSubscriptionCurrency';
import { getTranslationKey } from '../../modules/utils';
import { BillingAddressForm } from './subscription-payment/BillingAddressForm';
import { useSubscriptionBreakdown } from './helpers/useSubscriptionBreakdown';
import SubscriptionPaymentElement, {
  useIsSetup
} from './subscription-payment/SubscriptionPaymentElement';
import { getUser } from '../../modules/selectors';
import { useLocation } from 'react-router';
import qs from 'querystring';
import { PaymentMethods } from '../../modules/types/subscriptions';
import { formValueSelector } from 'redux-form';
import { connect, useSelector } from 'react-redux';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Wrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  min-height: 70vh;
  @media (max-width: ${media.desktop}) {
    width: 100%;
  }
`;

const PaneWrapper = styled(Col)`
  border-right: 10px solid ${theme.commonColors.secondBackground};
  background: ${theme.commonColors.mainBackground};
  @media (max-width: ${media.desktop}) {
    border-right: 0;
    margin-bottom: 20px;
  }
  padding-bottom: 20px;
`;

const Title = styled.div`
  margin: 0 0 20px 0;
  font-weight: bolder;
  font-size: 30px;
`;

function CheckoutForm() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [billingAddressHasUnsavedChanges, setBillingAddressHasUnsavedChanges] = useState(false);
  const [vatIdHasUnsavedChanges, setVatIdHasUnsavedChanges] = useState(false);
  const location = useLocation();
  const user = useSelector(getUser);
  const searchParams = qs.parse(location.search.substring(1));
  const setupFailed =
    searchParams.setup_failed || user.subscription.state === 'payment_setup_problem';

  const submitForm = () => {
    setIsSubmit(true);
  };

  const billingFormHasUnsavedChanges = billingAddressHasUnsavedChanges || vatIdHasUnsavedChanges;

  return (
    <Wrapper>
      {!setupFailed && (
        <PaneWrapper md={12} sm={12} lg={6} xl={4}>
          <Title data-cy="billingAddressModalTitle">
            {getTranslationKey('billingAddressModalTitle')}
          </Title>
          <BillingAddressForm
            showVatId
            showTitle={false}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            setVatIdHasUnsavedChanges={setVatIdHasUnsavedChanges}
            setBillingAddressHasUnsavedChanges={setBillingAddressHasUnsavedChanges}
          />
        </PaneWrapper>
      )}
      <PaneWrapper md={12} sm={12} lg={6} xl={4}>
        <SubscriptionPaymentElement
          data-cy="SubscriptionPaymentElement"
          submitForm={submitForm}
          isSubmit={isSubmit}
          billingFormHasUnsavedChanges={billingFormHasUnsavedChanges}
        />
      </PaneWrapper>
    </Wrapper>
  );
}

export const SubscriptionPayment = ({ country }: { country: string }) => {
  const subscriptionBreakdown = useSubscriptionBreakdown();
  const { subscriptionCurrency } = useSubscriptionCurrency();
  const location = useLocation();
  const searchParams = qs.parse(location.search.substring(1));
  const auth = useSelector(getUser);
  const isSetup =
    useIsSetup() || subscriptionBreakdown.totalGross === 0 || !!searchParams.setup_failed;
  const currency = subscriptionCurrency.toLowerCase() || 'eur';
  let paymentMethodTypes = PaymentMethods[currency as keyof typeof PaymentMethods];
  if (country === 'switzerland') {
    paymentMethodTypes = paymentMethodTypes.filter((p) => p !== 'paypal');
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: isSetup ? 'setup' : 'subscription',
        amount: isSetup
          ? null
          : Math.max(0, Math.floor(subscriptionBreakdown.totalGross * 100 || 0)),
        currency,
        paymentMethodCreation: isSetup ? 'manual' : undefined,
        locale: ['de', 'en'][auth.languageSoftware] as StripeElementLocale,
        payment_method_types: paymentMethodTypes
      }}
    >
      <CheckoutForm />
    </Elements>
  );
};

const selector = formValueSelector('billindAddressForm');
const mapStateToProps = (state: any) => ({
  country: selector(state, 'country')
});

export default connect(mapStateToProps)(SubscriptionPayment);
