import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import { getModalState } from '../../modules/selectors';
import { changeModalState } from '../../modules/actions';
import { get } from 'lodash';

export const BillingAddressChangeModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalState)('billingAddressChangeModal');
  const toggle = () =>
    dispatch(changeModalState({ key: 'billingAddressChangeModal', state: !isOpen }));

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-dark" size="md">
      <ModalHeader data-cy="modalHeaderBillingAddresss" toggle={toggle}>
        {getTranslationKey('billingAddressChangedModal.title')}
      </ModalHeader>
      <ModalBody data-cy="modalBodyBillingAddresss">
        <p>{getTranslationKey('billingAddressChangedModal.body')}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          data-cy="modalSecondaryButtonBillingAddresss"
          onClick={() => {
            if (get(window, 'Intercom.booted')) {
              // @ts-ignore
              window.Intercom('show');
            }
            toggle();
          }}
        >
          {getTranslationKey('billingAddressChangedModal.secondaryBtn')}
        </Button>
        <Button
          data-cy="modalPrimaryButtonBillingAddresss"
          className="primary-highlight-shadow"
          color="primary"
          onClick={() => {
            toggle();
          }}
        >
          <span className="d-sm-down-none">
            {getTranslationKey('billingAddressChangedModal.primaryBtn')}
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
