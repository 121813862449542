import { useState } from 'react';
import { TabListItem } from './TabsHeader';
import { ITabContent } from './TabContentComponent';

export interface ITab extends TabListItem, ITabContent {}

export function useTabs({ tabs, defaultActiveTab }: { tabs: ITab[]; defaultActiveTab?: string }) {
  tabs = tabs.filter((tab) => !tab.isHidden);
  const [activeTab, setActiveTab] = useState(defaultActiveTab || (tabs[0] && tabs[0].id));

  const toggle = (tabId: string) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  return {
    tabs,
    activeTab,
    toggle
  };
}
