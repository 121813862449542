import { cloneDeep } from 'lodash';
import ibanValidator from 'iban';
import { getTranslationKey } from '../../modules/utils';
import { IBANFields, PayoutMethodsFormState, PayPalFields } from './types';
import { canEnableIBAN, canEnablePayPal } from './common';

function handleFormChange(state: PayoutMethodsFormState, method: 'paypal' | 'iban') {
  if (method === 'paypal') {
    state.paypal.local.payPalEnabled =
      (state.paypal.ui.edit && canEnablePayPal(state[method].local)) ||
      state.paypal.local.payPalEnabled;
    state.paypal.validation = getPayPalValidation(state);
  }
  if (method === 'iban') {
    state.iban.local.ibanEnabled =
      (state.iban.ui.edit && canEnableIBAN(state[method].local)) || state.iban.local.ibanEnabled;
    state.iban.validation = getIBANValidation(state);
  }

  state[method].ui.showErrors = false;
}

const actionHandlers = {
  setPayPalFields: (state: PayoutMethodsFormState, action: Partial<PayPalFields>) => {
    state.paypal.local = { ...state.paypal.local, ...action };
    handleFormChange(state, 'paypal');
    if (action.payPalEnabled === false) {
      state.paypal.local.payPalEnabled = false;
    }
  },
  changePayPalFieldValue: <TField extends keyof PayoutMethodsFormState['paypal']['local']>(
    state: PayoutMethodsFormState,
    action: {
      field: TField;
      value: PayoutMethodsFormState['paypal']['local'][TField];
    }
  ) => {
    state.paypal.local[action.field] = action.value;
    handleFormChange(state, 'paypal');
    if (action.field === 'payPalEnabled' && action.value === false) {
      state.paypal.local.payPalEnabled = false;
    }
  },
  setIBANFields: (state: PayoutMethodsFormState, action: Partial<IBANFields>) => {
    state.iban.local = { ...state.iban.local, ...action };
    handleFormChange(state, 'iban');
    if (action.ibanEnabled === false) {
      state.iban.local.ibanEnabled = false;
    }
  },
  changeIBANFieldValue: <TField extends keyof PayoutMethodsFormState['iban']['local']>(
    state: PayoutMethodsFormState,
    action: {
      field: TField;
      value: PayoutMethodsFormState['iban']['local'][TField];
    }
  ) => {
    state.iban.local[action.field] = action.value;
    handleFormChange(state, 'iban');
    if (action.field === 'ibanEnabled' && action.value === false) {
      state.iban.local.ibanEnabled = false;
    }
  },
  toggleEditMode: <TMethod extends 'paypal' | 'iban'>(
    state: PayoutMethodsFormState,
    action: {
      method: TMethod;
      edit?: boolean;
    }
  ) => {
    state[action.method].ui.edit =
      typeof action.edit === 'boolean' ? action.edit : !state[action.method].ui.edit;
  },
  toggleErrors: <TMethod extends 'paypal' | 'iban'>(
    state: PayoutMethodsFormState,
    action: {
      method?: TMethod;
      show?: boolean;
    }
  ) => {
    const methods = action.method ? [action.method] : ['paypal', 'iban'];
    methods.forEach((method) => {
      state[method as 'paypal' | 'iban'].ui.showErrors =
        typeof action.show === 'boolean'
          ? action.show
          : !state[method as 'paypal' | 'iban'].ui.showErrors;
    });
  }
};

type ActionHandlers = typeof actionHandlers;
type ExtractActionParameters<T> = T extends (state: any, action: infer P) => any ? P : never;
type ActionTypes = keyof ActionHandlers;
export type Actions = {
  [K in ActionTypes]: {
    type: K;
  } & ExtractActionParameters<ActionHandlers[K]>;
}[ActionTypes];

export function payoutMethodsReducer(
  state: PayoutMethodsFormState,
  action: Actions
): PayoutMethodsFormState {
  state = cloneDeep(state);

  switch (action.type) {
    case 'setPayPalFields':
      actionHandlers.setPayPalFields(state, action);
      break;
    case 'changePayPalFieldValue':
      actionHandlers.changePayPalFieldValue(state, action);
      break;
    case 'setIBANFields':
      actionHandlers.setIBANFields(state, action);
      break;
    case 'changeIBANFieldValue':
      actionHandlers.changeIBANFieldValue(state, action);
      break;
    case 'toggleEditMode':
      actionHandlers.toggleEditMode(state, action);
      break;
    case 'toggleErrors':
      actionHandlers.toggleErrors(state, action);
      break;
  }

  return state;
}

export function getPayPalValidation(state: PayoutMethodsFormState) {
  const validation = { payPalClientId: '', payPalSecret: '', payPalEnabled: '' };

  if (state.paypal.ui.edit && !state.paypal.local.payPalClientId) {
    validation.payPalClientId = getTranslationKey('requiredInput');
  }

  if (state.paypal.ui.edit && !state.paypal.local.payPalSecret) {
    validation.payPalSecret = getTranslationKey('requiredInput');
  }

  return validation;
}

export function getIBANValidation(state: PayoutMethodsFormState) {
  const validation = { iban: '', accountHolder: '', ibanEnabled: '' };

  if (
    state.iban.ui.edit &&
    state.iban.local.iban &&
    !ibanValidator.isValid(state.iban.local.iban)
  ) {
    validation.iban = getTranslationKey('shop.settings.ibanInvalidMessage');
  }

  if (state.iban.ui.edit && !state.iban.local.iban) {
    validation.iban = getTranslationKey('shop.settings.ibanInvalidMessage');
  }

  if (state.iban.ui.edit && !state.iban.local.accountHolder) {
    validation.accountHolder = getTranslationKey('requiredInput');
  }

  return validation;
}
