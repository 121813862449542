import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import media from '../../assets/css/media';

const TabContentStyled = styled(TabContent)<{ height: string; isRemoveOverflow: boolean }>`
  background: transparent;
  border: none;
  margin-bottom: 20px;
  flex-grow: 1;
  ${(props) => (props.isRemoveOverflow ? 'overflow: hidden;' : 'overflow: visible;')};
  @media (min-width: ${media.desktop}) {
    height: ${(props) => (props.height ? props.height : 'calc(100vh - 320px)')};
    padding: 0;
  }
`;

const Tab = styled(TabPane)`
  position: relative;
  height: 100%;

  &.tab-pane {
    padding: 0;
  }
`;

export interface ITabContent {
  id: string;
  component: any;
  componentProps?: any;
}

interface IProps {
  activeTab: string;
  contentList: ITabContent[];
  height?: string;
  isRemoveOverflow?: boolean;
}

export const TabContentComponent = ({
  activeTab,
  contentList,
  height = 'auto',
  isRemoveOverflow = true
}: IProps) => (
  <TabContentStyled activeTab={activeTab} height={height} isRemoveOverflow={isRemoveOverflow}>
    {contentList.map(({ component: Component, componentProps = {}, ...item }) => (
      <Tab key={item.id} tabId={item.id}>
        <Component {...componentProps} activeTab={activeTab} />
      </Tab>
    ))}
  </TabContentStyled>
);

export default TabContentComponent;
