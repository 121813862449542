import { IAdressInterface } from '../modules/types/paymentInfo';

export function isBillingAddressFilled(billingAddress: IAdressInterface) {
  const isFilled =
    !!billingAddress.city &&
    !!billingAddress.company &&
    !!billingAddress.country &&
    !!billingAddress.email &&
    !!billingAddress.firstName &&
    !!billingAddress.lastName &&
    !!billingAddress.street &&
    !!billingAddress.zipcode &&
    !!billingAddress.streetNumber;

  return isFilled;
}
