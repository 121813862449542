import styled, { css } from 'styled-components';

const sizes = {
  tiny: '10px',
  small: '20px',
  mid: '30px',
  big: '40px'
};

export const Spacer = styled.div<{
  tiny?: boolean;
  small?: boolean;
  mid?: boolean;
  big?: boolean;
}>`
  ${({ tiny, small, mid, big }) => {
    let marginBottom = sizes.mid;

    if (tiny) {
      marginBottom = sizes.tiny;
    }
    if (small) {
      marginBottom = sizes.small;
    }
    if (mid) {
      marginBottom = sizes.mid;
    }
    if (big) {
      marginBottom = sizes.big;
    }

    return css`
      margin-bottom: ${marginBottom};
    `;
  }}
`;
