import React from 'react';
import { StyledTr, StyledTd } from '../../../index';
import { getTranslationKey } from '../../../../../../modules/utils/helpers/index';
import { getUser } from '../../../../../../modules/selectors/user';
import { useSelector } from 'react-redux';
import PaymentItem from '../paymentItem';
import CancelLink from './cancelLink/index';
import useTrackClick from '../../../../../../hooks/tracking/use-track-click';
import { SubscriptionType } from '../../../../../../modules/types/subscriptions';
import { useIsPaymentProblem } from '../../../../../../common/use-is-payment-problem';

interface INextPaymentItemProps {
  isMultipleSubscriptions: boolean;
  isLastElement: boolean;
  subscriptionType: SubscriptionType;
  nextPaymentAt: string;
  successfulPayments?: number;
}

const NextPaymentItem = ({
  isMultipleSubscriptions,
  isLastElement,
  subscriptionType,
  successfulPayments,
  nextPaymentAt
}: INextPaymentItemProps) => {
  const trackClick = useTrackClick();
  const { isPaymentProblem } = useIsPaymentProblem();
  const { digistore = {}, subscription = {} } = useSelector(getUser);

  const isCancelable = Boolean(digistore?.can_cancel_before);
  const isMonthlyIntervall = subscription?.intervall?.includes('monthly');
  const isAvailable = isCancelable && isMonthlyIntervall;
  const onCancelLinkClick = () => {
    trackClick('cancel-subscription', { subscription_type: subscriptionType });
  };

  return (
    <>
      <PaymentItem
        isMultipleSubscriptions={isMultipleSubscriptions}
        isLastElement={isLastElement}
        subscriptionType={subscriptionType}
        headingTitle={getTranslationKey('overviewTab.cancelSubscription')}
        extraOptionTitle={nextPaymentAt}
        onLinkClick={onCancelLinkClick}
        disableDefaultLink={isPaymentProblem}
        customLink={
          !isPaymentProblem || successfulPayments === 0 ? (
            <CancelLink subscriptionType={subscriptionType} />
          ) : null
        }
      />

      {isAvailable && isLastElement && (
        <StyledTr disableLine={!isLastElement}>
          <StyledTd paddingTop={0}>{getTranslationKey('overviewTab.minContract')}</StyledTd>
          <StyledTd paddingTop={0}>
            {digistore.can_cancel_before &&
              new Date(digistore.can_cancel_before).toLocaleDateString()}
          </StyledTd>
        </StyledTr>
      )}
      {!isAvailable && <StyledTr disableLine={!isLastElement} />}
    </>
  );
};

export default NextPaymentItem;
