import React from 'react';
import styled from 'styled-components';
import { Col, ColProps } from 'reactstrap';
import theme from '../../../assets/css/theme';

export const CARD_PADDING = 40;
const CardWrapper = styled(Col)`
  background: ${theme.commonColors.mainBackground};
  padding: ${(props) => (props.padded === false ? '0' : CARD_PADDING + 'px')};
  height: 100%;
`;

export const CardTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  font-weight: 350;
`;

const CardBase = styled.div<{ padded?: boolean }>`
  ${(props) => props.padded && `padding: ${CARD_PADDING}px;`}
`;

export const CardHeader = styled(CardBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardBody = CardBase;
export const CardSection = CardBase;
export const CardFooter = CardBase;

interface CardProps extends React.HTMLAttributes<HTMLDivElement>, ColProps {
  padded?: boolean;
}

export const Card: React.FC<CardProps> = (props) => (
  <CardWrapper {...props}>{props.children}</CardWrapper>
);
