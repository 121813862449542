import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import {
  BillingAddressForm,
  SubmitButton
} from '../subscription/subscription-payment/BillingAddressForm';
import { getTranslationKey } from '../../modules/utils';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { useStateChange } from '../../hooks/useStateChange';
import { Title } from './common';
import { isBillingAddressFilled } from '../../common/is-billing-address-filled';
import { DisplayCard, Header } from './DisplayCard';
import { useCountry } from '../../hooks/useCountry';

const FormWrapper = styled.div`
  width: 50%;
`;

const FiEditBtn = styled(FiEdit)`
  cursor: pointer;
  align-self: flex-start;
`;

const SubmitBtn: SubmitButton = ({ onClick, disabled }) => (
  <Button
    color="primary"
    className="px-5 mt-3 text-uppercase"
    disabled={disabled}
    type="button"
    onClick={onClick}
  >
    {getTranslationKey('save')}
  </Button>
);

const BillingAddressTabContent = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { updatingAddress, address: billingAddress } = useSelector(getPaymentInfo);
  const billingAddressIsFilled = isBillingAddressFilled(billingAddress);
  const [showForm, setShowForm] = useState(!billingAddressIsFilled);
  const country = useCountry(billingAddress.country);

  useEffect(() => {
    if (billingAddressIsFilled) {
      setShowForm(false);
    }
  }, [billingAddressIsFilled]);

  useStateChange(updatingAddress, (updatingAddressPrev, updatingAddress) => {
    if (updatingAddressPrev && !updatingAddress) {
      setShowForm(false);
      setIsSubmit(false);
    }
  });

  return (
    <div className="new-design">
      <Title className="mb-4">
        {getTranslationKey(
          showForm
            ? 'shop.paymentSettings.changeBillingAddress'
            : 'shop.paymentSettings.yourBillingAddress'
        )}
      </Title>
      <FormWrapper>
        {showForm ? (
          <BillingAddressForm
            showVatId={false}
            showTitle={false}
            saveOnBlur={false}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            setVatIdHasUnsavedChanges={() => {}}
            setBillingAddressHasUnsavedChanges={() => {}}
            submitButton={SubmitBtn}
            onCancel={billingAddressIsFilled ? () => setShowForm(false) : undefined}
          />
        ) : (
          <DisplayCard className="animated fadeIn">
            <Header>
              <div>
                <div>
                  {billingAddress.firstName} {billingAddress.lastName}
                </div>
                <div>{billingAddress.email}</div>
                <div>{billingAddress.company}</div>
                <div>
                  {billingAddress.street} {billingAddress.streetNumber}
                </div>
                <div>
                  {billingAddress.zipcode} {billingAddress.city}
                </div>
                <div>{country ? country.name : ''}</div>
              </div>
              <FiEditBtn size={20} onClick={() => setShowForm(true)} />
            </Header>
          </DisplayCard>
        )}
      </FormWrapper>
    </div>
  );
};

export const BillingAddressTab = BillingAddressTabContent;
