import React, { useEffect } from 'react';
import { SlPaypal } from 'react-icons/sl';
import { VscCreditCard } from 'react-icons/vsc';
import { getTranslationKey } from '../../modules/utils';
import styled from 'styled-components';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Title } from './common';
import { PayoutMethodCardForm } from './PayoutMethodCardForm';
import { fetchPayoutAccountAction } from '../../modules/actions';
import { InputItem } from '../../components';
import { usePayoutMethods } from './payout-methods';
import { CardTitle } from './DisplayCard';

const ViewWrapper = styled.div<{ background: string }>`
  position: relative;
  font-weight: 300;
  font-size: 1rem;
  color: #1d1d1d;
  div {
    white-space: nowrap;
    overflow: hidden;
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to left, ${(props) => props.background} 0px, transparent 10%);
  }
`;

const InputWrapper = styled.div`
  margin: 10px 0 10px 0;
`;

export const PayoutMethodsTab = () => {
  const dispatch = useDispatch();
  const payoutMethods = usePayoutMethods();

  useEffect(() => {
    dispatch(fetchPayoutAccountAction());
  }, []);

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    payoutMethods.submit();
  };

  const handleFocus = (event: React.BaseSyntheticEvent) => event.target.select();

  const submitButton = (
    <Button
      color="primary"
      className="px-5 mt-3 text-uppercase"
      disabled={
        !(payoutMethods.paypal.submissionAllowed() || payoutMethods.iban.submissionAllowed())
      }
      type="submit"
      onClick={submitForm}
    >
      {getTranslationKey('save')}
    </Button>
  );

  return (
    <div>
      <Title className="mb-4">{getTranslationKey('shop.paymentSettings.payoutMethodsTitle')}</Title>
      <div className="mb-5">{getTranslationKey('shop.paymentSettings.payoutMethodsInfo')}</div>
      <Row>
        <Col md={6}>
          <PayoutMethodCardForm
            enabled={payoutMethods.paypal.local.payPalEnabled}
            toggle={() => {
              payoutMethods.paypal.enable();
            }}
            enablingDisabled={!payoutMethods.paypal.enablingAllowed()}
            edit={payoutMethods.paypal.ui.edit}
            setEdit={payoutMethods.paypal.toggleEditMode}
            title={
              <CardTitle>
                <SlPaypal size={22} color="#1d1d1d" className="mr-2" />
                <span className="title mr-1">PayPal</span>
                <span className="subtitle">
                  {getTranslationKey('shop.paymentSettings.recommended')}
                </span>
              </CardTitle>
            }
            form={
              <>
                <InputWrapper>
                  <InputItem
                    id="paypal-client-id"
                    label={getTranslationKey('shop.settings.payPalClientId')}
                    type="text"
                    changeCallback={(v) => payoutMethods.paypal.changeField('payPalClientId', v)}
                    value={String(payoutMethods.paypal.local.payPalClientId)}
                    placeholder={getTranslationKey('shop.settings.payPalClientId')}
                    onFocus={handleFocus}
                  />
                  {payoutMethods.paypal.ui.showErrors &&
                    payoutMethods.paypal.validation.payPalClientId && (
                      <span>{payoutMethods.paypal.validation.payPalClientId}</span>
                    )}
                </InputWrapper>
                <InputWrapper>
                  <InputItem
                    id="paypal-client-secret"
                    label={getTranslationKey('shop.settings.payPalSecret')}
                    type="text"
                    changeCallback={(v) => payoutMethods.paypal.changeField('payPalSecret', v)}
                    value={String(payoutMethods.paypal.local.payPalSecret)}
                    placeholder={getTranslationKey('shop.settings.payPalSecret')}
                    onFocus={handleFocus}
                  />
                  {payoutMethods.paypal.ui.showErrors &&
                    payoutMethods.paypal.validation.payPalSecret && (
                      <span>{payoutMethods.paypal.validation.payPalSecret}</span>
                    )}
                </InputWrapper>
              </>
            }
            view={({ background }) => (
              <ViewWrapper background={background}>
                <div>{payoutMethods.paypal.remote.payPalClientId}</div>
                <div>{payoutMethods.paypal.remote.payPalSecret}</div>
              </ViewWrapper>
            )}
          />
          {payoutMethods.paypal.ui.edit && submitButton}
        </Col>
        <Col md={6}>
          <PayoutMethodCardForm
            enabled={payoutMethods.iban.local.ibanEnabled}
            toggle={() => {
              payoutMethods.iban.enable();
            }}
            enablingDisabled={!payoutMethods.iban.enablingAllowed()}
            edit={payoutMethods.iban.ui.edit}
            setEdit={payoutMethods.iban.toggleEditMode}
            title={
              <CardTitle>
                <VscCreditCard size={24} color="#1d1d1d" className="mr-2" />
                <span className="title mr-1">IBAN</span>
              </CardTitle>
            }
            form={
              <>
                <InputWrapper>
                  <InputItem
                    id="account-holder"
                    label={getTranslationKey('shop.settings.accountHolder')}
                    type="text"
                    changeCallback={(v) => payoutMethods.iban.changeField('accountHolder', v)}
                    value={String(payoutMethods.iban.local.accountHolder)}
                    placeholder={getTranslationKey('shop.settings.accountHolder')}
                    onFocus={handleFocus}
                  />
                  {payoutMethods.iban.ui.showErrors &&
                    payoutMethods.iban.validation.accountHolder && (
                      <span>{payoutMethods.iban.validation.accountHolder}</span>
                    )}
                </InputWrapper>
                <InputWrapper>
                  <InputItem
                    id="iban"
                    label={getTranslationKey('shop.settings.ibanInputLabel')}
                    type="text"
                    changeCallback={(v) => payoutMethods.iban.changeField('iban', v)}
                    value={String(payoutMethods.iban.local.iban)}
                    placeholder={getTranslationKey('shop.settings.ibanInputLabel')}
                    onFocus={handleFocus}
                  />
                  {payoutMethods.iban.ui.showErrors && payoutMethods.iban.validation.iban && (
                    <span>{payoutMethods.iban.validation.iban}</span>
                  )}
                </InputWrapper>
              </>
            }
            view={({ background }) => (
              <ViewWrapper background={background}>
                <div>{payoutMethods.iban.remote.accountHolder}</div>
                <div>{payoutMethods.iban.remote.iban}</div>
              </ViewWrapper>
            )}
          />
          {payoutMethods.iban.ui.edit && !payoutMethods.paypal.ui.edit && submitButton}
        </Col>
      </Row>
    </div>
  );
};
