import styled from 'styled-components';

export const DisplayCard = styled.div`
  padding: 1.5rem;
  font-size: 1.125rem;
  border-radius: 22px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  & .title {
    font-size: 1.5rem;
    color: #1d1d1d;
  }

  & .subtitle {
    font-size: 1.5rem;
    font-weight: 300;
    color: #acacac;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
