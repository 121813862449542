import React, { useState, useEffect } from 'react';
import { StyledTr, StyledTd } from '../../../index';
import { getTranslationKey } from '../../../../../../modules/utils/helpers/index';
import { getHTMLFromText } from '../../../../../../helpers/html';
import { ISubscriptionItem, SubscriptionType } from '../../../../../../modules/types/subscriptions';
import { getUser } from '../../../../../../modules/selectors/user';
import { useSelector } from 'react-redux';
import { getEuros } from '../index';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../../../../../../modules/types';

interface ITotalItem {
  subscriptions: ISubscriptionItem[];
  strikePrice?: boolean;
}

interface ISubscriptionTypes {
  type: SubscriptionType;
  interval: string;
}

const TotalItem = ({ subscriptions, strikePrice }: ITotalItem) => {
  const { subscription = {} } = useSelector(getUser);
  // const total = subscriptions.reduce((acc, { amountCentsGross }) => (acc += amountCentsGross), 0);
  const currency = subscriptions[0]?.currency || SHOP_CURRENCIES.EUR;
  const currencySymbol = CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES];

  const yearsMap: { [key: string]: string } = {
    yearly: getTranslationKey('overviewTab.billedYearly'),
    twoYearly: getTranslationKey('overviewTab.billedTwoYearly'),
    monthly: getTranslationKey('overviewTab.billedMonthly'),
    monthlyYearly: getTranslationKey('overviewTab.billedMonthly'),
    monthlyTwoYearly: getTranslationKey('overviewTab.billedMonthly')
  };

  const subscriptionTypesToSearch: ISubscriptionTypes[] = [
    { type: 'slideshow', interval: 'slideshowIntervall' },
    { type: 'video', interval: 'videoIntervall' },
    { type: 'brand', interval: 'brandIntervall' },
    { type: 'domain', interval: 'domainIntervall' }
  ];

  const [totalsList, setTotalsList] = useState(new Map());

  const processTotalsList = () => {
    const totalPriceByIntervalMap: Map<string, { total: number }> = new Map();

    const processTotalPriceValues = (intervall: string, items: ISubscriptionItem[]) => {
      const totalListItem = {
        total: items.reduce((acc, item) => (acc += item?.amountCentsDiscountedGross || 0), 0)
      };

      if (totalPriceByIntervalMap.has(intervall)) {
        const currentIntervalTotal = totalPriceByIntervalMap.get(intervall)?.total ?? 0;
        totalListItem.total += currentIntervalTotal;
      }

      return totalListItem;
    };

    const processSubscriptionTotalByType = (
      subscriptionType: SubscriptionType,
      subscriptionInterval: string
    ) => {
      if (subscription[subscriptionInterval]) {
        const subItem = subscriptions.find(
          (subscription) => subscription.subscriptionType === subscriptionType
        );

        if (subItem) {
          totalPriceByIntervalMap.set(
            subscription[subscriptionInterval],
            processTotalPriceValues(subscription[subscriptionInterval], [subItem])
          );
        }
      }
    };

    subscriptionTypesToSearch.forEach((subscriptionType) => {
      processSubscriptionTotalByType(subscriptionType.type, subscriptionType.interval);
    });

    const otherSubscriptions = subscriptions.filter((otherSubscriptionItem) => {
      const { subscriptionType } = otherSubscriptionItem;

      return !(
        (subscriptionType === 'slideshow' && subscription.slideshowIntervall) ||
        (subscriptionType === 'video' && subscription.videoIntervall) ||
        (subscriptionType === 'brand' && subscription.brandIntervall) ||
        (subscriptionType === 'domain' && subscription.domainIntervall)
      );
    });

    totalPriceByIntervalMap.set(
      subscription.intervall,
      processTotalPriceValues(subscription.intervall, otherSubscriptions)
    );

    setTotalsList(totalPriceByIntervalMap);
  };

  useEffect(() => {
    processTotalsList();
  }, [subscription, subscriptions]);

  return (
    <>
      <StyledTr disableLine>
        <StyledTd colSpan={2} align="left">
          {getHTMLFromText(getTranslationKey('overviewTab.total'))}
        </StyledTd>
      </StyledTr>
      {Array.from(totalsList).map(([intervall, subObject]) => (
        <StyledTr key={intervall} disableLine>
          <StyledTd paddingTop={0}>{yearsMap[intervall]}</StyledTd>
          <StyledTd textDecoration={strikePrice ? 'line-through' : null} paddingTop={0}>
            <b>
              {getEuros(subObject.total)}
              {currencySymbol}
            </b>
          </StyledTd>
        </StyledTr>
      ))}

      <StyledTr />
      {/* {totalsList.size === 1 && (
        <StyledTr>
          <StyledTd colSpan={2} align="right">
            <b>
              {getEuros(total)} {currencySymbol}
            </b>
          </StyledTd>
        </StyledTr>
      )} */}
    </>
  );
};

export default TotalItem;
