import React from 'react';
import { StyledTr, StyledTd } from '../../index';
import { getTranslationKey } from '../../../../../modules/utils/helpers';
import { ISubscriptionItem } from '../../../../../modules/types/subscriptions';
import TotalItem from './totalItem';
import RenewalLink from './renewalLink';
import Subtitle from './subtitle';
import NextPaymentItem from './nextPaymentItem';
import PaymentItem from './paymentItem';
import styled from 'styled-components';
import useTrackClick from '../../../../../hooks/tracking/use-track-click';
import { useSubscriptionCurrency } from '../../../../../modules/hooks/useSubscriptionCurrency';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../../../../../modules/types';

const TableTitle = styled.span`
  padding-right: 20px;
`;

const PlanTitle = styled.span`
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
`;

const CoreItem = ({ plan }: { plan: string }) => (
  <TableTitle>
    scrappbook: <PlanTitle>{plan}</PlanTitle>
  </TableTitle>
);

const AddonItem = ({ type }: { type: string }) => (
  <TableTitle>
    Addon: <PlanTitle>{type}</PlanTitle>
  </TableTitle>
);

export const getEuros = (cents: number) => (cents / 100).toFixed(2).replace('.', ',');

const Subscriptions = ({ subscriptions, plan, paymentProvider }: any) => {
  const trackClick = useTrackClick();
  const { currencySymbol } = useSubscriptionCurrency();
  const isFreePlan = plan === 'free' || plan === 'free-trial' || plan === 'freemium';

  const subscriptionPacksActive = subscriptions.filter(
    (item: ISubscriptionItem) => (item.renewUrl || item.stripeSubscriptionId) && !item.isCanceled
  );
  const isCoreCanceled = subscriptions.some(
    (item: ISubscriptionItem) => item.subscriptionType === 'core' && item.isCanceled
  );
  const subscriptionPacksCanceled = subscriptions.filter(
    (item: ISubscriptionItem) =>
      (item.renewUrl ||
        (isCoreCanceled ? item.subscriptionType === 'core' : paymentProvider === 'stripe')) &&
      item.isCanceled
  );
  // only stripe customers can cancel subscriptions that are not independent
  const coreSub = subscriptionPacksActive.find(
    (item: ISubscriptionItem) => item.subscriptionType === 'core'
  );
  const cancelableSubscriptions = [...subscriptionPacksActive];
  if (coreSub && paymentProvider === 'stripe') {
    cancelableSubscriptions.push(
      ...subscriptions
        .filter((item: ISubscriptionItem) => !item.isCanceled && !item.stripeSubscriptionId)
        .map((item: ISubscriptionItem) => ({ ...item, nextPaymentAt: coreSub?.nextPaymentAt }))
    );
  }

  const isMultipleSubscriptions = (subscriptions: ISubscriptionItem[]) => subscriptions.length > 1;

  const isLastElement = (subscriptions: ISubscriptionItem[], idx: number) =>
    idx === subscriptions.length - 1;

  const isCorePresent = subscriptions.some(
    (item: ISubscriptionItem) => item.subscriptionType === 'core'
  );

  if (!isCorePresent || isFreePlan) {
    return (
      <StyledTr>
        <StyledTd>
          <PlanTitle>{plan}</PlanTitle>
        </StyledTd>
        <StyledTd>0{currencySymbol}</StyledTd>
      </StyledTr>
    );
  }

  const onChangePaymentMethodLinkClick = (subscriptionType: string) => {
    trackClick('change-payment-method', { subscription_type: subscriptionType });
  };

  const onRestartSubscriptionLinkClick = (subscriptionType: string) => {
    trackClick('restart-subscription', { subscription_type: subscriptionType });
  };

  return (
    <>
      {subscriptions.map(
        ({ subscriptionType, amountCentsDiscountedGross, currency }: ISubscriptionItem) => (
          <StyledTr key={subscriptionType}>
            <StyledTd>
              {subscriptionType === 'core' && <CoreItem plan={plan} />}
              {subscriptionType !== 'core' && <AddonItem type={subscriptionType} />}
            </StyledTd>
            <StyledTd>
              {getTranslationKey('priceWithCurrency', {
                price: getEuros(amountCentsDiscountedGross),
                currency: CURRENCIES_SYMBOLS[currency.toUpperCase() as SHOP_CURRENCIES]
              })}
            </StyledTd>
          </StyledTr>
        )
      )}

      <TotalItem subscriptions={subscriptions} />

      {!!subscriptionPacksActive.length && (
        <Subtitle
          subtitle={getTranslationKey('overviewTab.nextPayment')}
          subscriptions={subscriptionPacksActive}
          extraInfo={new Date(subscriptionPacksActive[0].nextPaymentAt)?.toLocaleDateString()}
        />
      )}

      {cancelableSubscriptions?.map(
        (
          { subscriptionType, nextPaymentAt, successfulPayments }: ISubscriptionItem,
          idx: number
        ) => (
          <NextPaymentItem
            key={subscriptionType}
            isMultipleSubscriptions={isMultipleSubscriptions(cancelableSubscriptions)}
            isLastElement={isLastElement(cancelableSubscriptions, idx)}
            subscriptionType={subscriptionType}
            nextPaymentAt={new Date(nextPaymentAt)?.toLocaleDateString()}
            successfulPayments={successfulPayments || 0}
          />
        )
      )}

      {!!subscriptionPacksCanceled.length && (
        <Subtitle
          subtitle={getTranslationKey('overviewTab.subscriptionEnds')}
          subscriptions={subscriptionPacksCanceled}
          extraInfo={
            subscriptionPacksCanceled[0].canCancelBefore
              ? new Date(subscriptionPacksCanceled[0].canCancelBefore)?.toLocaleDateString()
              : new Date(
                  !subscriptionPacksCanceled[0].stripeSubscriptionId && coreSub?.canCancelBefore
                    ? coreSub?.canCancelBefore
                    : subscriptionPacksCanceled[0]?.nextPaymentAt || coreSub?.nextPaymentAt
                )?.toLocaleDateString()
          }
        />
      )}

      {subscriptionPacksCanceled?.map(
        (
          {
            subscriptionType,
            nextPaymentAt,
            stripeSubscriptionId,
            canCancelBefore
          }: ISubscriptionItem,
          idx: number
        ) => (
          <PaymentItem
            key={subscriptionType}
            isMultipleSubscriptions={isMultipleSubscriptions(subscriptionPacksCanceled)}
            isLastElement={isLastElement(subscriptionPacksCanceled, idx)}
            subscriptionType={subscriptionType}
            headingTitle={getTranslationKey('overviewTab.restartSubscription')}
            extraOptionTitle={
              canCancelBefore
                ? new Date(canCancelBefore)?.toLocaleDateString()
                : new Date(
                    !stripeSubscriptionId && coreSub?.canCancelBefore
                      ? coreSub?.canCancelBefore
                      : nextPaymentAt ||
                        subscriptionPacksCanceled[0]?.nextPaymentAt ||
                        coreSub?.nextPaymentAt
                  )?.toLocaleDateString()
            }
            disableDefaultLink
            onLinkClick={() => onRestartSubscriptionLinkClick(subscriptionType)}
            customLink={
              <RenewalLink
                nextPaymentAt={nextPaymentAt || coreSub?.nextPaymentAt}
                subscriptionType={subscriptionType}
              />
            }
          />
        )
      )}

      {!!subscriptionPacksActive.length && (
        <Subtitle
          subtitle={getTranslationKey('overviewTab.paymentMethod')}
          subscriptions={subscriptionPacksActive}
          extraInfo={subscriptionPacksActive[0]?.paymentMethod}
        />
      )}

      {subscriptionPacksActive?.map(
        (
          { subscriptionType, paymentMethod, renewUrl, stripeSubscriptionId }: ISubscriptionItem,
          idx: number
        ) => (
          <PaymentItem
            key={subscriptionType}
            isMultipleSubscriptions={isMultipleSubscriptions(subscriptionPacksActive)}
            isLastElement={isLastElement(subscriptionPacksActive, idx)}
            subscriptionType={subscriptionType}
            headingTitle={getTranslationKey('overviewTab.changePayment')}
            extraOptionTitle={paymentMethod}
            renewUrl={
              stripeSubscriptionId
                ? `${window.API_URL}/api/subscription/customer-portal-session`
                : renewUrl
            }
            onLinkClick={() => onChangePaymentMethodLinkClick(subscriptionType)}
          />
        )
      )}
    </>
  );
};

export default Subscriptions;
